import { Spinner } from "@edgetier/components";
import { ILanguage, IPaginated, Permission, Url } from "@edgetier/types";
import Axios, { AxiosError, AxiosResponse } from "axios";
import { StatusCodes } from "http-status-codes";
import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import UserStateProvider from "components-for/users-states/user-state-provider";
import Navigation from "components/navigation/navigation";
import QueryHistoryThreadPage from "components/query-history-thread-page";
import View from "constants/view";
import { ISettings } from "redux/application.types";
import { authenticationOperations } from "redux/modules/authentication";
import { connectSocket } from "redux/modules/chat/socket";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import { setupOperations, setupSelectors, setupUtilities } from "redux/modules/setup";
import { ISetupData } from "redux/modules/setup/setup.types";
import { IApplicationState } from "redux/types";
import ChatService from "services/chat-service";
import EmailService from "services/email-service";
import axios, { resetResponseInterceptor } from "utilities/axios";
import requestCurrentUser from "utilities/request-current-user";
import saveHeaders from "utilities/save-headers";
import AnimatedRouteSwitch from "./animated-route-switch";
import { IProps, IStateProps } from "./authenticated-routes.types";

import { FeatureFlagsIdentify } from "components-for/feature-flags";
import { NavigationNotificationsProvider } from "components-for/navigation/navigation-notifications-provider";
import VerifyPermission from "components-for/verify-permission/verify-permission";
import { SETTINGS_PERMISSIONS } from "constants/permission";
import { getRelativePath } from "constants/routes";
import { proactiveChatOperations } from "redux/modules/proactive-chat";
import { IRole } from "types-for/permissions/permissions.types";
import { PageNames } from "types-for/routes";
import { getEmailAccountsError } from "utilities-for/email-accounts/get-email-accounts-errors";
import "./authenticated-routes.scss";

// Lazy loading routes
const ActionsPage = lazy(() => import("components/actions-page/actions-page"));
const AgentHome = lazy(() => import("components/agent-home/agent-home"));
const Chat = lazy(() => import("components/chat/chat"));
const CreateEmail = lazy(() => import("components/create-email/create-email"));
const DeferredQueries = lazy(() => import("components/deferred-queries/deferred-queries"));
const Query = lazy(() => import("components/query/query"));
const Reporting = lazy(() => import("components/reporting/reporting"));
const Search = lazy(() => import("components/search"));
const Settings = lazy(() => import("components/settings"));
const Users = lazy(() => import("components/users"));
const ProactiveChat = lazy(() => import("components/proactive-chat"));
const Permissions = lazy(() => import("components/permissions"));
/**
 * Container for all views that require the user to be signed in. This component also handles validating access tokens
 * when a user refreshes their browsers or leaves and returns to the application. Once the user has been authenticated,
 * the "setup" data is downloaded as it is needed all throughout the application.
 */
export class AuthenticatedRoutes extends React.PureComponent<IProps> {
    // Cancel token for general requests.
    cancelTokenSource = Axios.CancelToken.source();

    /**
     * Either check the user's credentials or download the setup data.
     */
    componentDidMount(): void {
        if (this.props.isSignedIn) {
            this.handleSignIn();
        } else {
            this.validateToken();
        }
    }

    /**
     * Handling signing in and out, and enabling or disabling email.
     */
    componentDidUpdate(previousProps: IProps): void {
        // Watch for the user signing in or out of the application.
        if (previousProps.isSignedIn === false && this.props.isSignedIn === true) {
            this.handleSignIn();
        } else if (previousProps.isSignedIn === true && this.props.isSignedIn === false) {
            this.handleSignOut();
        }
    }

    /**
     * When this component unmounts the user is leaving the authenticated part of the application.
     */
    componentWillUnmount(): void {
        // Cancel all other requests.
        this.cancelTokenSource.cancel();

        // Remove the unauthenticated requests interceptor since the user will now be signed out.
        resetResponseInterceptor();
    }

    /**
     * When a user signs in, their socket must be connected and setup data downloaded.
     */
    handleSignIn(): void {
        // Register interceptors that will sign the user out on any unauthorised request from this point onwards.
        this.registerInterceptors();

        // Get the setup data if it doesn't exist already.
        if (!this.props.isSetupDataDownloaded) {
            this.requestSetupData();
        }
        connectSocket();
    }

    /**
     * Sign the user out. This is triggered by changes to the store.
     */
    async handleSignOut(): Promise<void> {
        this.props.showLoadingBlocker(true);

        // Sign the user out. Their credentials are deleted from local storage whether this is successful or not because
        // even if the backend somehow refuses their sign out attempt, they should still be signed out.
        try {
            await axios.delete(Url.ValidateUser, { cancelToken: this.cancelTokenSource.token });
            localStorage.clear();
        } catch {
            localStorage.clear();
        } finally {
            this.props.hideLoadingBlocker(true);
        }

        // Go the login screen.
        this.props.push(View.Login);
    }

    /**
     * Now that the user is inside the authenticated routes, any unauthorised request should sign them out. This is
     * implemented by adding an interceptor to all Axios responses.
     */
    registerInterceptors(): void {
        axios.interceptors.response.use(
            function (response: AxiosResponse): AxiosResponse {
                saveHeaders(response.headers);
                return response;
            },
            (serverError: AxiosError): Promise<AxiosError> => {
                // Sign the user out on unauthorised requests.
                const { response } = serverError;
                if (response && response.status === StatusCodes.UNAUTHORIZED) {
                    // Mark in the store that the user is signed out, redirect to the login page, and clear their user
                    // ID and token from local storage.
                    this.props.signOut();
                    localStorage.clear();
                } else if (typeof response !== "undefined") {
                    // Normal errors should save the access token still, otherwise subsequent requests fail.
                    saveHeaders(response.headers);
                }

                return Promise.reject(serverError);
            }
        );
    }

    /**
     * Render authenticated routes once the user has been authenticated and the setup data is available.
     * @returns Routes.
     */
    render(): React.ReactNode {
        const { featuresToggles, roleId, isProactiveChatEnabledOnUser } = this.props;
        const { chatFunctionalityEnabled, emailFunctionalityEnabled, proactiveChatEnabled } = featuresToggles;
        const useChat = chatFunctionalityEnabled;
        const useEmail = emailFunctionalityEnabled;

        return (
            this.props.isSetupDataDownloaded &&
            this.props.isSignedIn && (
                <div className="application__authenticated-routes">
                    <UserStateProvider>
                        <FeatureFlagsIdentify />
                        <VerifyPermission permission={Permission.HandleInteraction}>
                            {useChat && (
                                <ChatService
                                    roleId={roleId!}
                                    onServerError={this.props.showServerError}
                                    isChatFocussed={this.props.pathname === View.Chat}
                                />
                            )}
                            {useEmail && <EmailService onServerError={this.props.showServerError} />}
                        </VerifyPermission>

                        <NavigationNotificationsProvider
                            pageNotificationsConfiguration={[
                                {
                                    pageName: PageNames.SettingsEmailAccounts,
                                    notificationRequest: getEmailAccountsError,
                                },
                            ]}
                        >
                            <Navigation {...this.props.featuresToggles} />
                            <AnimatedRouteSwitch className="application__authenticated-routes__pages">
                                <Route
                                    path={getRelativePath(PageNames.Main, PageNames.AgentHome) ?? ""}
                                    element={
                                        <VerifyPermission permission={Permission.HandleInteraction} isRoute>
                                            <Suspense fallback={<Spinner />}>
                                                <AgentHome />
                                            </Suspense>
                                        </VerifyPermission>
                                    }
                                />

                                {chatFunctionalityEnabled && (
                                    <Route
                                        path={getRelativePath(PageNames.Main, PageNames.Chat) ?? ""}
                                        element={
                                            <VerifyPermission permission={Permission.HandleInteraction} isRoute>
                                                <Suspense fallback={<Spinner />}>
                                                    <Chat />
                                                </Suspense>
                                            </VerifyPermission>
                                        }
                                    />
                                )}

                                {emailFunctionalityEnabled && (
                                    <Route
                                        path={getRelativePath(PageNames.Main, PageNames.CreateEmail) ?? ""}
                                        element={
                                            <VerifyPermission permission={Permission.HandleInteraction} isRoute>
                                                <Suspense fallback={<Spinner />}>
                                                    <CreateEmail />
                                                </Suspense>
                                            </VerifyPermission>
                                        }
                                    />
                                )}

                                {emailFunctionalityEnabled && (
                                    <Route
                                        path={getRelativePath(PageNames.Main, PageNames.DeferredQueries) ?? ""}
                                        element={
                                            <VerifyPermission permission={Permission.HandleInteraction} isRoute>
                                                <Suspense fallback={<Spinner />}>
                                                    <DeferredQueries showServerError={this.props.showServerError} />
                                                </Suspense>
                                            </VerifyPermission>
                                        }
                                    />
                                )}

                                {emailFunctionalityEnabled && (
                                    <Route
                                        path={getRelativePath(PageNames.Main, PageNames.Email) ?? ""}
                                        element={
                                            <VerifyPermission permission={Permission.HandleInteraction} isRoute>
                                                <Suspense fallback={<Spinner />}>
                                                    <Query />
                                                </Suspense>
                                            </VerifyPermission>
                                        }
                                    />
                                )}

                                <Route
                                    path={`${getRelativePath(PageNames.Main, PageNames.Reporting)}/*`}
                                    element={
                                        <VerifyPermission permission={Permission.ViewReporting} isRoute>
                                            <Suspense fallback={<Spinner />}>
                                                <Reporting />
                                            </Suspense>
                                        </VerifyPermission>
                                    }
                                />

                                <Route
                                    path={`${getRelativePath(PageNames.Main, PageNames.Search)}/*`}
                                    element={
                                        <VerifyPermission permission={Permission.ViewSearch} isRoute>
                                            <Suspense fallback={<Spinner />}>
                                                <Search />
                                            </Suspense>
                                        </VerifyPermission>
                                    }
                                />

                                <Route
                                    path={`${getRelativePath(PageNames.Main, PageNames.Settings)}/*`}
                                    element={
                                        <VerifyPermission permission={SETTINGS_PERMISSIONS} isRoute>
                                            <Suspense fallback={<Spinner />}>
                                                <Settings />
                                            </Suspense>
                                        </VerifyPermission>
                                    }
                                />

                                {proactiveChatEnabled && isProactiveChatEnabledOnUser && (
                                    <Route
                                        path={getRelativePath(PageNames.Main, PageNames.ProactiveChat) ?? ""}
                                        element={
                                            <VerifyPermission permission={Permission.HandleInteraction} isRoute>
                                                <Suspense fallback={<Spinner />}>
                                                    <ProactiveChat />
                                                </Suspense>
                                            </VerifyPermission>
                                        }
                                    />
                                )}

                                <Route
                                    path={getRelativePath(PageNames.Main, PageNames.QueryHistory) ?? ""}
                                    element={
                                        <VerifyPermission permission={Permission.ViewSearch} isRoute>
                                            <QueryHistoryThreadPage />
                                        </VerifyPermission>
                                    }
                                />

                                <Route
                                    path={getRelativePath(PageNames.Main, PageNames.Actions) ?? ""}
                                    element={
                                        <VerifyPermission
                                            permission={[Permission.ViewAction, Permission.EditAction]}
                                            isRoute
                                        >
                                            <Suspense fallback={<Spinner />}>
                                                <ActionsPage />
                                            </Suspense>
                                        </VerifyPermission>
                                    }
                                />

                                <Route
                                    path={getRelativePath(PageNames.Main, PageNames.Users) ?? ""}
                                    element={
                                        <VerifyPermission
                                            permission={[Permission.EditUser, Permission.EditUserBasic]}
                                            isRoute
                                        >
                                            <Suspense fallback={<Spinner />}>
                                                <Users />
                                            </Suspense>
                                        </VerifyPermission>
                                    }
                                />

                                <Route
                                    path={getRelativePath(PageNames.Main, PageNames.Permissions) ?? ""}
                                    element={
                                        <VerifyPermission permission={Permission.EditRole} isRoute>
                                            <Suspense fallback={<Spinner />}>
                                                <Permissions />
                                            </Suspense>
                                        </VerifyPermission>
                                    }
                                ></Route>
                            </AnimatedRouteSwitch>
                        </NavigationNotificationsProvider>
                    </UserStateProvider>
                </div>
            )
        );
    }

    /**
     * Download the setup data. This includes basic data needed throughout the application.
     */
    async requestSetupData(): Promise<void> {
        try {
            const configuration = { cancelToken: this.cancelTokenSource.token };

            // Request the setup data, languages and settings.
            const [{ data: setup }, { data: languages }, { data: settings }, { data: user }] = await Promise.all([
                axios.get<ISetupData>(Url.Setup, configuration),
                axios.get<IPaginated<ILanguage>>(Url.Languages, configuration),
                axios.get<ISettings>(Url.Settings, configuration),
                requestCurrentUser(configuration),
            ]);

            this.props.storeSetupData({
                ...setup,
                ...setupUtilities.getFeaturesFromSettings(settings),
                languages: languages.items,
            });
            this.props.storeProactiveChatData({
                isProactiveChatEnabledOnUser: user?.isProactiveChatEnabled ?? false,
            });
        } catch (serverError) {
            if (Axios.isAxiosError(serverError)) {
                this.props.showServerError(serverError);
            }
        }
    }

    /**
     * Validate a user when they already have authorisation credentials in local storage but are not signed in to the
     * application. This could happen after a browser refresh or closing and returning to the application.
     */
    async validateToken(): Promise<void> {
        try {
            const { data } = await axios.get<{ email: string; roleId: IRole["roleId"] }>(Url.ValidateToken);
            this.props.signIn(data.email, data.roleId);
            connectSocket();
        } catch (serverError) {
            if (!Axios.isCancel(serverError) && Axios.isAxiosError(serverError)) {
                this.props.push(View.Login);
            }
        }
    }
}

/**
 * See if the setup data has been downloaded and if the user is signed in.
 * @param state Application state.
 * @returns     Setup data and sign in state.
 */
export function mapStateToProps({ authentication, setup, proactiveChat }: IApplicationState): IStateProps {
    return {
        featuresToggles: setupSelectors.getFeatureToggles(setup),
        isProactiveChatEnabledOnUser: proactiveChat.isProactiveChatEnabledOnUser,
        isSetupDataDownloaded: typeof setup.data !== "undefined",
        isSignedIn: typeof authentication.userEmail === "string",
        roleId: authentication.roleId,
    };
}

const mapDispatchToProps = {
    ...authenticationOperations,
    ...loadingBlockerOperations,
    ...setupOperations,
    ...proactiveChatOperations,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoutes);
