import NavigationNotificationsContext from "context-for/navigation-notifications/navigation-notifications-context";
import { FunctionComponent, memo, useContext } from "react";
import { NavigationItemNotificationCount } from "../navigation-item-notification-count";
import type { IProps } from "./navigation-group-notification-count.types";

/**
 * Displays the notification count for the navigation group. It gets the value through context.
 * @param pageNames The names of the pages to get the grouped notification count for.
 */
const NavigationGroupNotificationCount: FunctionComponent<IProps> = ({ pageNames }) => {
    const { notificationMap } = useContext(NavigationNotificationsContext);

    const groupNotificationCount = pageNames
        .map((page) => notificationMap[page] ?? 0)
        .reduce((acc, count) => acc + count, 0);

    return groupNotificationCount === 0 ? null : (
        <NavigationItemNotificationCount notificationCount={groupNotificationCount} />
    );
};

export default memo(NavigationGroupNotificationCount);
