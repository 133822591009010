import { getRouteHandle } from "constants/routes";
import type { IPageNotificationsConfiguration } from "./navigation-notifications-provider.types";

/**
 * Get the permissions for each page in the page notifications configuration.
 * @param pageNotificationsConfiguration The configuration for the notifications for each page.
 * @returns An array of permissions.
 */
export const getPagePermissions = (pageNotificationsConfiguration: IPageNotificationsConfiguration[]) => {
    return pageNotificationsConfiguration.flatMap(({ pageName }) => {
        const handle = getRouteHandle(pageName);
        const permission = handle?.permission;
        if (Array.isArray(permission)) {
            return permission;
        }
        return permission ? [permission] : [];
    });
};
