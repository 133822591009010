import { IPermission, Permission } from "@edgetier/types";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";
import { REDIRECT_ROUTE_KEY } from "./authenticated-redirect.constants";
import { PERMISSION_PAGE_MAPPING } from "constants/permission";

/**
 * Using user's role and permission find the appropriate available page to redirect after a successful authentication.
 * @param userRolePermissions   Permissions selected for user's role.
 */
export const findRedirectPage = (userRolePermissions?: IPermission[]) => {
    const redirectRoute = localStorage.getItem(REDIRECT_ROUTE_KEY);

    if (Array.isArray(userRolePermissions) && userRolePermissions.length > 0) {
        // A redirect route exists
        if (typeof redirectRoute === "string") {
            const [redirectPath] = redirectRoute.split("?");

            // Swap the elements of permission mapping to quick accessing the permission based on page name
            const reversePageMapping = new Map<string, Permission>(
                Array.from(PERMISSION_PAGE_MAPPING, (entry) => [entry[1], entry[0]])
            );

            const filteredRoutes = Array.from(reversePageMapping.keys()).filter((value) =>
                redirectPath.includes(value)
            );

            if (filteredRoutes.length > 0) {
                const [pickedRoute] = filteredRoutes;

                // Check if the user has permission for the redirect route
                const hasRedirectPermission = userRolePermissions.some(
                    ({ permissionId }) => reversePageMapping.get(pickedRoute) === permissionId
                );

                if (hasRedirectPermission) {
                    localStorage.removeItem(REDIRECT_ROUTE_KEY);
                    return redirectRoute;
                }
            }
        }

        for (const [permission, page] of Array.from(PERMISSION_PAGE_MAPPING)) {
            const hasPermission = userRolePermissions.some(({ permissionId }) => permission === permissionId);
            if (hasPermission) {
                localStorage.removeItem(REDIRECT_ROUTE_KEY);
                return page;
            }
        }
    }

    return AbsolutePaths[PageNames.Login]();
};
